<template>
  <div>
    <!-- Resultado de cada momento contable respectivamente -->
    <b-card no-body class="my-3">
      <b-tabs card>
        <b-tab v-for="res in importPoliza.result" :key="res.id" :title="res.content.poliza.momento">
          <polizas-administracion-card :item="res"></polizas-administracion-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PolizasAdministracionCard from '@/components/Polizas/Administracion/PolizasAdministracionCard'

export default {
  name: 'PolizasAdministracionTabs',

  components: {
    PolizasAdministracionCard
  },

  computed: {
    ...mapState('polizasAModule', ['importPoliza'])
  }
}
</script>

<style lang="scss" scoped></style>
